import React from 'react';
import Modal from '../../../components/Modal';
import Quote from '../../../components/Quote';

const media = {
  secTitle: 'Education and Inequality Go Hand in Hand',
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/mjjkmLycYdk',
  },
};

export default () => (
  <Modal closeTo="Learned" media={media}>
    <p className="Title--2 Title--c">
      Chronically failing public schools are viewed not only as a deterioration
      of investment in the common good, but as a tool for intentionally
      perpetuating patterns of disadvantage along racial lines.
    </p>
    <p>
      According to a study conducted by researchers from the Economic Policy
      Institute and Stanford University, poor black children are much more
      likely to attend high-poverty schools than white children. They found that
      81.1 percent of poor black children attended high poverty schools in 2013,
      compared with just 53.5 percent of poor white children.
    </p>
    <p>
      The United States as a whole is experiencing a growing divide in
      educational investments and outcomes based on family income. There is a
      widening gap between the investments that high- and low-income families
      make in their children. According to a study by The Hamilton Project, over
      the past four decades, high-income families have gone from spending
      slightly more than four times as much as low-income families to nearly
      seven times more.9 In a study of U.S. Census data, The Brookings Institute
      found that while the gap in high school completion is closing, black and
      Latino students are still less likely than their white counterparts to
      have a high school diploma.
    </p>
    <p>
      Entrenched patterns of segregation and the stark racial divide embodied by
      public schools cause a lack of collective willingness on the part of
      residents to contribute to the greater public good by funding education.
      Chronically failing public schools are viewed not only as a deterioration
      of investment in the common good, but as a tool for intentionally
      perpetuating patterns of disadvantage along racial lines.
    </p>
    <p>
      Ultimately, the erosion of quality public education is a product of
      longstanding racial injustices, a cause of ongoing racial and economic
      inequality, and a force of division in communities.
    </p>
    <Quote
      quote="When you have schools that are either 98% Caucasian or 98% children of color, ultimately those schools don’t end up getting the same level of resources and those students don’t have the same level of opportunities."
      person="White male, 36"
      title="Charlotte"
    />
    <Quote
      quote="Nobody’s willing to pay taxes…It’s, you know, ‘I’ve got mine. Why, why should I give more to benefit everybody else?’ I think it’s holding the South back, that lack of emphasis on education."
      person="White male, 52"
      title="Montgomery"
    />
  </Modal>
);
