import React from 'react';
import PropTypes from 'prop-types';

class Quote extends React.Component {
  static propTypes = {
    quote: PropTypes.string.isRequired,
    person: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  };

  render() {
    const { quote, person, title } = this.props;

    return (
      <blockquote>
        <p className="Title--3">&ldquo;{quote}&rdquo;</p>
        <div>
          <p className="Title--6">
            &mdash; {person},<span> {title} </span>
          </p>
        </div>
      </blockquote>
    );
  }
}

export default Quote;
